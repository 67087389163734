/*
 * 官网登录注册接口
 * */
import request from '@/utils/request'
import qs from 'qs'

/*
 * 发送短信验证码
 * */
export function getVerifyCode(parameter) {
  return request({
    url: '/work/send_verify',
    method: 'post',
    data: parameter,
  })
}
/**
 * 注册店铺
 */
export function register(parameter) {
  return request({
    url: '/work/register',
    method: 'post',
    data: parameter,
  })
}
/**
 * 账号密码登录
 */
export function singleLogin(parameter) {
  return request({
    url: '/work/single_login',
    method: 'post',
    data: parameter,
  })
}

/**
 * 账号店铺登录
 */
export function selectShop(parameter) {
  return request({
    url: '/work/select_shop',
    method: 'post',
    data: parameter,
  })
}

/**
 * 图形验证码
 */
export function getCaptcha() {
  return request({
    url: '/work/captcha',
    method: 'get',
  })
}

/** *********************重置密码*************************/

// 重置密码
export const resetPassword = (params) =>
  request.post('/work/reset_password', params)

/** *********************短信消息*************************/

// 获取短信消息列表
export const noticeSmsList = (params) =>
  request.get(`/work/notice/sms/list?${qs.stringify(params)}`)

// 设置短信消息全部已读
export const noticeSmsSetAllRead = (params) =>
  request.post('/work/notice/sms/set_all_read', params)

// 设置短信消息已读
export const noticeSmsSetRead = (params) =>
  request.post('/work/notice/sms/set_read', params)

// 套餐列表
export const packageList = (params) =>
  request.post('/work/package/list', params)

// 套餐订单列表
export const packageOrderList = (params) =>
  request.post('/work/package/orderList', params)

// 套餐订单确认
export const packageConfirmOrder = (params) =>
  request.post('/work/package/confirm_order', params)
